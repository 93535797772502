<template>
    <section class="bg-two" id="gallery">
        <div class="container">
            <div class="row">
                <div class="mb-5">
                    <img src="../assets/image/icon.webp" width="50">
                    <div class="titulo-sobre">{{ translateAll.our }} <span class="texto-destaque">{{
                        translateAll.gallery }}</span></div>
                    <div class="separator"></div>
                    <div class="text-card " :class="sizeWidth > 770 ? 'text-center' : 'text-start'">
                        <span class="mb-3">
                            {{ translateAll.see_what_they_say }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div v-for="(row, rowIndex) in itemsGallery" :key="rowIndex"
                class="org-img gap-2 ">
                <div v-for="(item, colIndex) in row" :key="colIndex"
                    :class="Array.isArray(item) ? 'd-flex flex-column' : ''">
                    <div v-for="(image, imgIndex) in (Array.isArray(item) ? item : [item])" :key="imgIndex">
                        <div class="card-depoimento">
                            <div class="img-depoimento">
                                <img :src="image.src" class="img-fluid" />
                            </div>
                            <div class="text-image" v-if="image.text">
                                <span>{{ image.text }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { eventBus } from "@/main";
import Pt from "@/language/Pt.json";
import En from "@/language/En.json";
import Es from "@/language/Es.json";

export default {
    data() {
        return {
            sizeWidth: window.innerWidth,
            itemsGallery: [],
        }
    },
    created() {
        let languageSite = new URLSearchParams(window.location.search).get("language");

        if (languageSite === "pt") {
            this.translateAll = Pt[0];

        } else if (languageSite === "en") {
            this.translateAll = En[0];

        } else if (languageSite === "es") {
            this.translateAll = Es[0];

        } else {
            this.translateAll = Pt[0];
        }

        eventBus.$on("pt", (pt) => {
            this.translateAll = pt;
            this.gallery()
        });
        eventBus.$on("en", (en) => {
            this.translateAll = en;
            this.gallery()
        });
        eventBus.$on("es", (es) => {
            this.translateAll = es;
            this.gallery();

        });
        this.gallery()
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    methods: {
        handleResize() {
            this.sizeWidth = window.innerWidth;
        },
        gallery() {
            this.itemsGallery = [
                // Primeira linha
                [
                    { text: this.translateAll.gallery1, src: require('../assets/image/galeria1.jpg') },
                    { text: this.translateAll.galeria3, src: require('../assets/image/galeria3.jpeg') },
                    { text: this.translateAll.gallery1, src: require('../assets/image/galeria2.jpg') },
                    { text: this.translateAll.gallery1, src: require('../assets/image/galeria3.1.jpg') },
                ],
                // Segunda linha (incluindo colunas com múltiplas imagens)
                [
                    { text: this.translateAll.gallery1, src: require('../assets/image/galeria4.4.jpg') },
                    [
                        { text: this.translateAll.dente1, src: require('../assets/image/dente1.jpeg') },
                        { text: this.translateAll.dente2, src: require('../assets/image/dente2.jpeg') }
                    ],
                    [
                        { text: this.translateAll.dente3, src: require('../assets/image/dente3.jpeg') },
                        { text: this.translateAll.dente3, src: require('../assets/image/dente4.jpeg') }
                    ]
                ],
                // Terceira linha
                [
                    { text: this.translateAll.foto1, src: require('../assets/image/foto1.jpeg') },
                    [
                        { text: this.translateAll.foto2, src: require('../assets/image/foto2.jpeg') },
                        { text: this.translateAll.foto3, src: require('../assets/image/foto3.jpeg') }
                    ]
                ]
            ];
        }

    },
}
</script>
<style>
.img-depoimento img {
    border: 1px solid #eeeeee;
}

.descricao {
    margin-top: 20px;
    font-size: 1em;
    line-height: 1.5;
    font-family: "Mulish";
    text-align: start;
}

.icon-size {
    font-size: 2.5em;
}

.card-depoimento {
    position: relative;
    padding: 0;
    margin-bottom: 10px;
    width: 100%;
    overflow: hidden;
    transition: transform 0.3s;
}

.text-image {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    color: #fff;
    background: rgba(0, 0, 0, 0.8);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: top 0.4s ease;
    font-family: "Mulish";
    font-size: 1.4rem;
}


.card-depoimento:hover .text-image {
    top: 0;
}
.org-img{
    display: flex;
    justify-content: center;
}

@media (max-width: 576px) {
    .org-img{
        display: flex;
        flex-direction: column;
    }
}
</style>