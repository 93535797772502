<template>
    <div>
        <header id="navigation" class="menu-superior">
            <div class="container">
                <div class="navbar-header w-100">
                    <nav class="navbar navbar-expand-lg navbar-dark py-2">
                        <a class="navbar-brand logo" href="/">
                            <img src="../assets/image/brand.webp" width="40" />
                        </a>
                        <b-navbar-toggle class="navbar-toggler" target="navbar-toggle-collapse">
                            <template #default="{ expanded }">
                                <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
                                <b-icon v-else icon="chevron-bar-down"></b-icon>
                            </template>
                        </b-navbar-toggle>
                        <b-collapse id="navbar-toggle-collapse" is-nav>
                            <div
                                class="collapse-content d-flex flex-column flex-lg-row align-items-center justify-content-between w-100">
                                <ul class="navbar-nav navigation-menu mb-3 mb-lg-0">
                                    <li class="nav-item"> <a href="/" class="nav-link"
                                            :class="{ active: isActive('home') }" @click="setActive('home')">{{
                                            translateAll.home }} </a> </li>
                                    <li class="nav-item" > <a href="#about" class="nav-link"
                                            :class="{ active: isActive('about') }" @click="setActive('about')">{{
                                            translateAll.about }} </a>
                                    <li class="nav-item" > <a href="#our-solution" class="nav-link"
                                            :class="{ active: isActive('our-solution') }"
                                                @click="setActive('our-solution')">{{
                                                translateAll.specialties }} </a> </li>
                                    </li>
                                   
                                    <li class="nav-item" > <a href="#contact-us"
                                            class="nav-link text-capitalize" :class="{ active: isActive('contact-us') }"
                                            @click="setActive('contact-us')">{{ translateAll.contact
                                            }} </a> </li>
                                    <li class="nav-item"> <a href="#gallery"
                                            class="nav-link text-capitalize virtualtour"
                                            @click="setActive('gallery')">{{ translateAll.gallery }} </a>
                                    </li>
                                </ul>
                                <div class="d-flex flex-row flex-lg-row align-items-center">
                                    <button class="btn-flag mb-2 mb-lg-0 ml-lg-2" @click="brasil">
                                        <img src="@/assets/image/brazil.svg" />
                                    </button>
                                    <button class="btn-flag mb-2 mb-lg-0 ml-lg-2" @click="usa">
                                        <img src="@/assets/image/usa.svg" />
                                    </button>
                                    <button class="btn-flag mb-2 mb-lg-0 ml-lg-2" @click="spain">
                                        <img src="@/assets/image/spain.svg" />
                                    </button>
                                </div>
                            </div>
                        </b-collapse>
                    </nav>
                </div>
            </div>
        </header>
    </div>
</template>
<script>
import { eventBus } from "@/main";
import Pt from "@/language/Pt.json";
import En from "@/language/En.json";
import Es from "@/language/Es.json";

export default {
    data() {
        return {
            translateAll: [],
            translateEn: [],
            queryLanguage: "",

            activeLink: null,
            links: [
                { id: 0, href: "#home", text: { pt: "Início", en: "Home", es: "Inicio" } },
                { id: 1, href: "#about", text: { pt: "Sobre", en: "About", es: "Sobre" } },
                { id: 2, href: "#our-team", text: { pt: "Equipe", en: "Team", es: "Equipo" } },
                { id: 3, href: "#our-solution", text: { pt: "Especialidades", en: "Specialties", es: "Especialidades" } },
                { id: 4, href: "#contact-us", text: { pt: "Contato", en: "Contact", es: "Contacto" } },
            ],
        };
    },
    mounted() {
        this.activeLink = this.links[0];
    },
    created() {
        this.translatePt = Pt;
        this.translateEn = En;
        this.translateEs = Es;

        this.translateAll = this.translatePt[0];

        let languageSite = new URLSearchParams(window.location.search).get("language");

    
        this.links.forEach(link => {
            if (languageSite === "pt") {
                link.translatedText = link.text.pt;
                this.translateAll = this.translatePt[0];
                eventBus.$emit("pt", this.translateAll);
            } else if (languageSite === "en") {
                link.translatedText = link.text.en;
                eventBus.$emit("en", this.translateAll);

                this.translateAll = this.translateEn[0];
            } else if (languageSite === "es") {
                link.translatedText = link.text.es;
                this.translateAll = this.translateEs[0];
                eventBus.$emit("es", this.translateAll);
            } else {
                link.translatedText = link.text.pt;
                eventBus.$emit("pt", this.translateAll);
                this.translateAll = this.translatePt[0];
            }
        });



    },
    methods: {
        brasil() {
            this.translateAll = this.translatePt[0];

            eventBus.$emit("pt", this.translateAll);
            this.$router.replace({ query: { language: "pt" } });
        },
        usa() {
            this.translateAll = this.translateEn[0];

            eventBus.$emit("en", this.translateAll);
            this.$router.replace({ query: { language: "en" } });
        },
        spain() {
            this.translateAll = this.translateEs[0];

            eventBus.$emit("es", this.translateAll);
            this.$router.replace({ query: { language: "es" } });
        },
        setActive(link) {

            this.activeLink = link;
        },
        isActive(link) {
            return this.activeLink === link;
        },

    },
};
</script>
<style>
.btn-flag img {
    width: 25px;
    height: 25px;
}

.btn-flag {
    background-color: transparent;
    border: none;
}

.menu-superior {
    background: #191a17;
}

.logo {
    color: #17AEE3 !important;
}

.nav-link.active {
    color: #17AEE3 !important;
    font-weight: bold !important;
}

.nav-link {
    font-family: "Mulish";
    font-size: 18px !important;
}

.menu-superior {
    border-bottom: 1px solid #17aee31f;
}

.virtualtour,
.txt-destaque {
    color: #17AEE3 !important;
    font-weight: bold !important;
}
</style>
